import {imageUrl} from 'common/sanity';
import {breakpoint} from 'common/theme';
import {SanityImageContentData} from 'common/types';
import {Col, Row} from 'components/grid';
import Link from 'next/link';
import {FC} from 'react';
import styled, {DefaultTheme} from 'styled-components';
import NextImage from 'next/image';
// @ts-ignore
import colorAlpha from 'color-alpha';

const Wrapper = styled.section<{reverse?: boolean}>`
  margin-top: ${({theme}) => theme.spacing(2 + 1.75 * 0.5)}px;
  margin-bottom: ${({theme}) => theme.spacing(1)}px;
  padding-bottom: ${({theme}) => theme.spacing(1)}px;
  padding-left: ${({theme}) => theme.spacing(1)}px;
  padding-right: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    margin-top: ${({theme}) => theme.spacing(2 + 2.5 * 0.5)}px;
    padding-left: ${({theme}) => theme.spacing(2)}px;
    padding-right: ${({theme}) => theme.spacing(2)}px;
  `}
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(3 + 3.5 * 0.5)}px;
    margin-bottom: ${({theme}) => theme.spacing(1)}px;
    padding-bottom: ${({theme}) => theme.spacing(2)}px;
  `};
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  background: linear-gradient(
    ${({reverse}) => (reverse ? `90deg` : `270deg`)},
    ${({theme}) => theme.colors.white} 70%,
    ${({theme}) => theme.colors.lightblue} 30%
  );
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1<{reverse?: boolean}>`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
  line-height: 1.1em;
  margin-top: -0.5em;
  display: inline-block;
  font-size: ${({theme}) => theme.fontSize(1.75)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(2.5)}px;
    margin-bottom: ${({reverse}: {reverse?: boolean}) => (!reverse ? `0.75em` : null)};
    margin-top: ${({reverse}: {reverse?: boolean}) => (reverse ? `0.75em` : null)};
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(3.5)}px;
    margin-bottom: ${({reverse}: {reverse?: boolean}) => (!reverse ? `1em` : null)};
    margin-top: ${({reverse}: {reverse?: boolean}) => (reverse ? `1em` : null)};
  `}
`;

const ImageWrapper = styled.div<{
  reverse?: boolean;
  background: string;
}>`
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-top: ${({theme, reverse}) => (!reverse ? `${theme.spacing(1)}px` : null)};
  background-color: ${({background}) => background};
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(1.5)}px;
  `};
`;

const TextWrapper = styled.div<{reverse?: boolean}>`
  padding-top: ${({theme}) => theme.spacing(0.5)}px;
  padding-bottom: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    padding-top: ${({theme}) => theme.spacing(1.5)}px;
    padding-bottom: ${({theme}) => theme.spacing(1.5)}px;
  `}
  ${breakpoint.size('md')`
    padding-left: ${({theme, reverse}: {theme: DefaultTheme; reverse?: boolean}) =>
      !reverse ? theme.spacing(2.5) : null}px;
    padding-right: ${({theme, reverse}: {theme: DefaultTheme; reverse?: boolean}) =>
      reverse ? theme.spacing(2.5) : null}px;
  `}
  ${breakpoint.size('lg')`
    adding-left: ${({theme, reverse}: {theme: DefaultTheme; reverse?: boolean}) =>
      !reverse ? theme.spacing(3.5) : null}px;
    padding-right: ${({theme, reverse}: {theme: DefaultTheme; reverse?: boolean}) =>
      reverse ? theme.spacing(3.5) : null}px;
  `}
`;

const Subtitle = styled.h2`
  width: 100%;
  margin: 0.5em 0;
  padding: 0;
  line-height: 1.25em;
  font-family: ${({theme}) => theme.fonts.merriweather};
  font-size: ${({theme}) => theme.fontSize(1.15)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.5)}px;
    margin-bottom: 0.75em 0;
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(1.75)}px;
    margin-bottom: 1em 0;
  `}
`;

const TextContent = styled.p`
  font-size: ${({theme}) => theme.fontSize(0.8)}px;
  line-height: 1.35em;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1)}px;
  `}
`;

const LinkItem = styled.a`
  font-weight: 500;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  color: ${({theme}) => theme.colors.blue};
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1.1)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.2)}px;
  `}
  &:hover {
    opacity: 0.8;
  }
  & > span:nth-child(2) {
    margin-left: 0.25em;
  }
`;

const ImageContent: FC<SanityImageContentData> = props => (
  <Wrapper reverse={props.reverse}>
    <Content>
      {!!props.title && <Title reverse={props.reverse}>{props.title}</Title>}
      <Row reverse={props.reverse}>
        <Col size={{xs: 1, md: 2 / 5, lg: 4 / 9}}>
          <ImageWrapper background={colorAlpha(props.image.dominant.background, 0.4)} reverse={props.reverse}>
            <NextImage
              layout='responsive'
              width={props.image.width}
              height={props.image.height}
              placeholder='blur'
              blurDataURL={props.image.lqip}
              src={
                imageUrl(props.image)
                  .width(600)
                  .auto('format')
                  .quality(80)
                  .url() || ''
              }
            />
          </ImageWrapper>
        </Col>
        <Col size={{xs: 1, md: 3 / 5, lg: 5 / 9}}>
          <TextWrapper reverse={props.reverse}>
            <Subtitle>{props.subTitle}</Subtitle>
            <TextContent
              dangerouslySetInnerHTML={{
                __html: props.content.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
              }}
            />
            {props.link && props.link !== '' && (
              <Link href={props.link} passHref>
                <LinkItem>
                  <span>weitere Details</span> <span>→</span>
                </LinkItem>
              </Link>
            )}
          </TextWrapper>
        </Col>
      </Row>
    </Content>
  </Wrapper>
);

export default ImageContent;
